<template>
  <div class="invoice-wrapper">
    <div class="invoice-header">
      <div class="left">
        <h3>ORDERS</h3>
      </div>
      <div class="right">
        <div class="controls">
          <div class="button" @click="onUpdateAll()">
            <p>Update All</p>
            <span class="icon is-right">
                <i class="fas fa-check fa-sm"></i>
              </span>
          </div>
        </div>
      </div>

    </div>

    <div class="disabled invoice-body">
      <div class="invoice-card">
        <div class="invoice-section is-flex is-bordered">
          <div class="end"><h3>User: demo user </h3></div>
        </div>
        <div class="invoice-section">
          <div class="flex-table">
            <div class="flex-table-header" style="position:relative;top:0rem;"><span class="is-grow"
                                                                                     style="flex-grow:2;">Strategy</span><span
                style="flex-grow:0;padding-left:4rem;padding-right:2rem;"> Buy/Sell</span><span
                style="flex-grow:0.8;padding-left:2rem;margin-right:4rem;">Quantity</span><span
                style="flex-grow:0.3;margin-right:0rem;margin-left:0rem;">Price</span><span>% done</span><span
                class="cell-end">Status</span></div>
            <div class="list">
              <div class="flex-table-item">
                <div class="flex-table-cell is-grow" data-th="" style="font-weight: 600;"><span
                    class="is-bold dark-text">SFRU3 v SERVX 0</span></div>
                <div class="flex-table-cell" data-th="Buy/Sell"><span class="light-text"
                                                                      style="color: rgb(173, 33, 0) !important; font-weight: 600;">Sell</span>
                </div>
                <div class="flex-table-cell" data-th="Quantity"><span class="light-text">1000</span></div>
                <div class="flex-table-cell" data-th="Price"><span class="dark-inverted">-3.34</span></div>
                <div class="flex-table-cell qtygoal" data-th="%   Done"><span class="light-text"
                                                                              style="margin-right: -1rem; padding-right: 0px;">75 %</span>
                </div>
                <div class="flex-table-cell has-text-right" data-th="Status"><span class="dark-inverted"
                                                                                   style="color: rgb(3, 245, 3) !important;">Active</span>
                </div>
              </div>
            </div>
            <div class="list">
              <div class="flex-table-item">
                <div class="flex-table-cell is-grow" data-th="" style="font-weight: 600;"><span
                    class="is-bold dark-text">TYFLY (50/50) 0.015</span></div>
                <div class="flex-table-cell" data-th="Buy/Sell"><span class="light-text"
                                                                      style="color: rgb(173, 33, 0) !important; font-weight: 600;">Sell</span>
                </div>
                <div class="flex-table-cell" data-th="Quantity"><span class="light-text">500</span></div>
                <div class="flex-table-cell" data-th="Price"><span class="dark-inverted">60.802</span></div>
                <div class="flex-table-cell qtygoal" data-th="%   Done"><span class="light-text"
                                                                              style="margin-right: -1rem; padding-right: 0px;">100 %</span>
                </div>
                <div class="flex-table-cell has-text-right" data-th="Status"><span class="dark-inverted"
                                                                                   style="color: rgb(255, 48, 0) !important;">Done</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="controls">
        <div class="button">
          <p>Update All</p>
          <span class="icon is-right">
                <i class="fas fa-check fa-sm"></i>
              </span>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import useNotyf from '../../composable/useNotyf'

const notif = useNotyf()
export default {
  components: {},
  setup() {

  },
  data() {

  },
  computed: {},

  mounted() {


  },
  methods: {}
}
</script>


<style lang="scss">
@import 'src/scss/abstracts/_mixins.scss';

/* ==========================================================================
4. Invoice
========================================================================== */


dark-inverted {
  #active {
    color: green !important;
  }

}

.drop {
  @media (min-width: 768px) {
    margin-left: 15px
  }
}

.qtygoal {
  @media (min-width: 768px) {
    margin-right: 28px
  }
}

.invoice-wrapper {
  max-width: 940px;
  margin: 0 auto;

  &.is-navbar {
    margin-top: 30px;
  }

  .invoice-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--fade-grey-dark-4);

    .left {
      h3 {
        font-family: var(--font-alt);
        font-size: 2.2rem;
        font-weight: 600;
        color: var(--dark-text);
      }
    }

    .right {
      .controls {
        display: flex;

        .action {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 32px;
          width: 32px;
          min-width: 32px;
          border-radius: var(--radius-rounded);
          color: var(--light-text);
          margin: 0 4px;
          transition: all 0.3s;

          &:hover {
            color: var(--dark-text);
            background: var(--fade-grey-dark-4);
          }

          svg {
            height: 16px;
            width: 16px;
            stroke-width: 1.6px;
          }
        }
      }
    }
  }

  .invoice-body {
    .invoice-card {

      @include vuero-s-card();
      margin-bottom: 10px;
      padding: 0;

      .invoice-section {
        padding: 20px;

        &.is-flex {
          display: flex;
          align-items: center;

          .meta {
            margin-left: 16px;
            font-family: var(--font);

            h3 {
              font-family: var(--font-alt);
              font-size: 1.1rem;
              font-weight: 600;
              line-height: 1;
            }

            span {
              display: block;
              color: var(--light-text);
              font-weight: 400;
              font-size: 0.9rem;
            }
          }

          .end {
            margin-left: auto;
            text-align: right;

            &.is-left {
              text-align: left;
              max-width: 300px;

              p {
                padding-top: 4px;
                font-size: 0.95rem;
                line-height: 1.2;
              }
            }

            h3 {
              font-family: var(--font-alt);
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 1;
            }

            span {
              display: block;
              color: var(--light-text);
              font-weight: 400;
              font-size: 0.9rem;
            }
          }
        }

        &.is-bordered {
          border-bottom: 1px solid var(--fade-grey-dark-3);
        }

        .v-avatar {
          &.is-customer {
            border: 1.6px solid var(--fade-grey-dark-3);
            border-radius: var(--radius-rounded);
            box-shadow: var(--light-box-shadow);
          }
        }

        .flex-table {
          &.sub-table {
            .flex-table-item {
              padding-top: 0;
              padding-bottom: 0;
              margin-bottom: 0;
              min-height: 40px;
              border: none;

              .table-label {
                font-family: var(--font);
                text-transform: uppercase;
                font-size: 0.8rem;
                color: var(--light-text);
              }

              .table-total {
                font-family: var(--font);
                color: var(--dark-text);
                font-weight: 500;

                &.is-bigger {
                  font-size: 1.2rem;
                  font-weight: 600;
                }
              }
            }
          }

          .flex-table-header {
            span {
              &:not(:first-child) {
                justify-content: flex-end;
              }
            }
          }

          .flex-table-item {
            .flex-table-cell {
              &:not(:first-child) {
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
5. Invoice Dark mode
========================================================================== */

.is-dark {
  .invoice-wrapper {
    .invoice-header {
      border-color: var(--dark-sidebar-light-20);

      .left {
        h3 {
          color: var(--dark-dark-text);
        }
      }

      .right {
        .controls {
          .action {
            border: 1px solid transparent;

            &:hover {
              background: var(--dark-sidebar-light-2);
              border-color: var(--primary);
              color: var(--primary);
            }
          }
        }
      }
    }

    .invoice-body {
      .invoice-card {
        @include vuero-card--dark();
      }
    }

    .invoice-section {
      border-color: var(--dark-sidebar-light-12) !important;

      &.is-flex {
        .v-avatar {
          border-color: var(--dark-sidebar-light-12) !important;
        }

        .meta,
        .end {
          h3 {
            color: var(--dark-dark-text);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .invoice-wrapper {
    .invoice-section {
      &.is-flex {
        flex-direction: column;
        text-align: center;

        .v-avatar {
          margin-bottom: 16px;
        }

        .meta {
          margin-left: 0 !important;
        }

        .end {
          margin: 16px auto 0 auto;
          text-align: center !important;
        }
      }

      .flex-table {
        &.sub-table {
          padding-top: 16px;

          .is-vhidden {
            display: none !important;
          }

          .flex-table-item:not(.is-vhidden) {
            flex-direction: revert !important;
          }
        }

        .flex-table-item {
          .flex-table-cell {
            &.is-grow {
              > span {
                margin-left: 0;
                flex-grow: 1.3 !important;
              }
            }
          }
        }
      }
    }
  }
}


.currenttime {
  padding: 10px;
  font-weight: 900;
}

table {
  border-radius: 3px;

  .hiddencolumn {
    display: none;

  }
}


.list.disabled {
  display: none;
}

@media (max-width: 767px) {
  .view-wrapper {
    width: 100% !important;
    margin-left: 0 !important;
    // margin-top: 10px !important;
  }
}

@media screen and (max-width: 992px) {
  table {
    overflow-x: auto;
    /* line-height: 0.65; */
  }

}

.yyielddrift {
  color: #8aaeff !important;
  font-weight: bold;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.strat_up {
  -webkit-animation-name: flash;
  -webkit-animation-duration: 7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.strat_down {
  -webkit-animation-name: flash2;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

@-webkit-keyframes flash {
  from {
    color: #ffffff;
    font-weight: bold;
  }

  to {
    color: #a7a7a7;
    font-weight: bold;
  }

}

@-webkit-keyframes flash2 {
  from {
    color: #ffffff;
    font-weight: bold;
  }

  to {
    color: #a7a7a7;
    font-weight: bold;
  }

}

th, td {
  padding: 0.4em 0.2em !important;
  text-align: right !important
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

#gridContainer span.current-value {
  display: inline-block;
  margin-right: 5px;
}

#gridContainer span.diff {
  width: 50px;
  display: inline-block;
}

.inc_a span {
  color: #81d76e !important;
  text-align: right !important;
}

.inc_a {
  color: #ababab;
  font-weight: bold;
}

.dec_a {
  color: #ff0000;
  font-weight: bold;
}

.dec_a span {
  color: #db5f5f;
  text-align: right !important;

}

.close {
  color: #f8e12f;
  text-align: right !important
}

.strat {
  text-align: left !important;
  padding-left: 6px !important;
}

.strat_r {
  text-align: right !important;
}

.price {
  text-align: left !important;
  padding-left: 47px !important;
}

.change {
  padding-left: 24px !important;
}

.inc_a .arrow,
.dec_a .arrow {
  display: inline-block;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

.inc_a .arrow {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

.dec_a .arrow {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}

.container-load {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: #fff;
  }
}

.list-edit {
  input {
    font-size: 1.1rem;

    &.is-disabled {
      opacity: 0.6;
    }

    &[name="exchangeName"] {
      font-size: 1.5rem;
      font-weight: 900;
    }
  }
}
</style>
